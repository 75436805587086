/* 🐜 Animations for ants 🐜 */

/**
 * Animate an element once it enters the viewport
 * @param {(string|NodeList)} selector - A css selector string or NodeList
 * @param {Object} [options] - Animation options
 * @param {string} options.type - Type of animation to play once entered viewport
 * @param {string} options.easing - CSS animation function
 * @param {number} options.delay - Delay till animation starts in ms
 * @param {number} options.duration - Duration of animation
 * @param {number} options.offset - Pixel offset before animation starts
 * @param {boolean} options.once - If animation should play once
 * @param {string} options.customClass - Optional custom class once element starts animating
 * @param {string} options.initClass - Class to add after animation initilization
 */
export const animateOnScroll = (selector, options) => {
  const elements =
    typeof selector === 'string'
      ? document.querySelectorAll(selector)
      : selector;

  if (elements.length < 1) return; // check if any element is selected

  const validTypes = [
    'fade-up',
    'fade-right',
    'fade-down',
    'fade-left',
    'fade',
    'custom', // used for custom effects (basically only ads observer and class)
  ];

  // default settings
  const defaults = {
    type: 'fade-up',
    easing: 'ease',
    delay: 0,
    duration: 400,
    offset: 120,
    once: true,
    customClass: '',
    initClass: 'ani',
  };

  // merge defaults with user options
  options = Object.assign(defaults, options);

  // interaction observer options
  const ops = {
    root: null, // viewport
    threshold: 0,
    rootMargin: `${options.offset}px`,
  };

  // create intersection observer
  const observer = new IntersectionObserver((entries, observer) => {
    entries.forEach((entry) => {
      if (entry.isIntersecting) {
        // element entered view
        if (options.customClass != '') {
          entry.target.parentElement.querySelector('.performance-theme').classList.remove(options.customClass);
          entry.target.parentElement.querySelector('.performance-subtitle').classList.remove(options.customClass);
        }

        if (validTypes.includes(options.type)) {
          entry.target.parentElement.querySelector('.performance-theme').classList.remove(`ani-${options.type}`);
          entry.target.parentElement.querySelector('.performance-subtitle').classList.remove(`ani-${options.type}`);
        }

        if (options.once) {
          observer.unobserve(entry.target); // remove observer
        }
      } else {
        // element out of view
        entry.target.parentElement.querySelector('.performance-theme').classList.add(`ani-${options.type}`);
        entry.target.parentElement.querySelector('.performance-subtitle').classList.add(`ani-${options.type}`);

        if (options.customClass != '') {
          entry.target.parentElement.querySelector('.performance-theme').classList.add(options.customClass);
          entry.target.parentElement.querySelector('.performance-subtitle').classList.add(options.customClass);
        }
      }
    });
  }, ops);

  // add observer to elements
  if (validTypes.includes(options.type)) {
    for (const el of elements) {
      el.parentElement.querySelector('.performance-theme').style.transitionProperty = 'transform, opacity';
      el.parentElement.querySelector('.performance-subtitle').style.transitionProperty = 'transform, opacity';
      el.parentElement.querySelector('.performance-theme').style.transitionTimingFunction = `${options.easing}`;
      el.parentElement.querySelector('.performance-subtitle').style.transitionTimingFunction = `${options.easing}`;
      el.parentElement.querySelector('.performance-theme').style.transitionDuration = `${options.duration / 1000}s`;
      el.parentElement.querySelector('.performance-subtitle').style.transitionDuration = `${options.duration / 1000}s`;
      el.parentElement.querySelector('.performance-theme').style.transitionDelay = `${options.delay / 1000}s`;
      el.parentElement.querySelector('.performance-subtitle').style.transitionDelay = `${options.delay / 1000}s`;
      el.parentElement.querySelector('.performance-theme').classList.add(`ani-${options.type}`);
      el.parentElement.querySelector('.performance-subtitle').classList.add(`ani-${options.type}`);

      observer.observe(el);
    }
  }
};

/**
 * Sticky element move withing the container element
 * @param {string} selector - A css selector string
 * @param {Object} [options] - Animation options
 *
 * @example
 * // example html
 * // div.container
 * //   span.sticky
 * //     p content /p
 * //  /span
 * // /div
 * advancedSticky('.sticky', {})
 */
export const advancedSticky = (selector, options) => {
  // limit effect to certain viewport widths?

  const elements = document.querySelectorAll(selector);

  if (elements.length < 1) return; // check if any element is selected

  // interaction observer options
  const ops = {
    root: null, // viewport
    threshold: 0,
    rootMargin: '0px',
  };

  // default settings
  const defaults = {};

  // merge defaults with user options
  options = Object.assign(defaults, options);

  let animationQueue = [];
  let vpH = window.innerHeight;

  // create intersection observer
  const observer = new IntersectionObserver((entries, observer) => {
    entries.forEach((entry) => {
      if (entry.isIntersecting) {
        // element entered view -> push to animationQueue
        const parent = entry.target;
        const child = entry.target.querySelector(selector); // element that actually gets transformed

        animationQueue.push({
          parent: parent,
          element: child,
          startPos: 0,
          endPos: parent.offsetHeight - child.offsetHeight,
          totalScroll: vpH + parent.offsetHeight,
        });
      } else {
        // element out of view -> remove from animationQueue
        const index = animationQueue.findIndex(
          (item) => item.parent === entry.target
        );
        if (index !== -1) {
          animationQueue.splice(index, 1);
        }
      }
    });
  }, ops);

  // add observer to elements
  for (const el of elements) {
    observer.observe(el.parentElement);
  }

  // recalculate values when viewport changes
  window.addEventListener(
    'resize',
    throttle(() => {
      vpH = window.innerHeight;
      animationQueue = animationQueue.map((item) => {
        item.startPos = 0;
        item.endPos = item.parent.offsetHeight - item.element.offsetHeight;
        item.totalScroll = vpH + item.parent.offsetHeight;

        return item;
      });
    }, 75)
  );

  function onAnimationFrame() {
    const windowY = window.scrollY;
    const vpH = window.innerHeight; // move this to more general location?

    // loop through animationQueue
    for (const item of animationQueue) {
      // calculate item offset
      const offset = item.parent.offsetTop - vpH; // element height - 1 viewport height
      const t = (windowY - offset) / item.totalScroll; // % off scroll [0 - 1]

      // lerp, clamp and set translate
      const transformOffset = clamp(
        lerp(item.startPos, item.endPos, t),
        item.startPos,
        item.endPos
      );
      item.element.style.transform = `translateY(${transformOffset}px)`;
    }

    requestAnimationFrame(onAnimationFrame);
  }

  // kick start raf
  requestAnimationFrame(onAnimationFrame);
};

/**
 * Parralax an element
 * @param {(string|NodeList)} selector - A css selector string or NodeList
 * @param {Object} [options] - Animation options
 * @param {number} [options.speed=0.5] - Element scroll speed modifier
 */
export const parralax = (selector, options) => {
  // This function could probably also use the interaction observer, you just have to make sure the translations
  // get set on load, after a resize and once they go out of view?

  const elements =
    typeof selector === 'string'
      ? document.querySelectorAll(selector)
      : selector;

  if (elements.length < 1) return; // check if any element is selected

  // interaction observer options
  const ops = {
    root: null, // viewport
    threshold: 0,
    rootMargin: '0px',
  };

  // default settings
  const defaults = {
    speed: 0.5,
  };

  // merge defaults with user options
  options = Object.assign(defaults, options);

  let animationQueue = [];
  let vpH = window.innerHeight;

  const observer = new IntersectionObserver((entries, observer) => {
    entries.forEach((entry) => {
      if (entry.isIntersecting) {
        // element entered view -> push to animationQueue
        const element = entry.target;
        animationQueue.push({
          element: element,
          startPos: 0,
        });
      } else {
        // element out of view -> remove from animationQueue
        const index = animationQueue.findIndex((item) => item === entry.target);
        if (index !== -1) {
          animationQueue.splice(index, 1);
        }
      }
    });
  }, ops);

  // add observer to elements
  for (const el of elements) {
    observer.observe(el);
  }

  // recalculate values when viewport changes
  window.addEventListener(
    'resize',
    throttle(() => {
      vpH = window.innerHeight;
    }, 75)
  );

  function onAnimationFrame() {
    // parralax is the time it takes for an element to travel 1 viewport height
    // 0 is default scroll speed

    const windowY = window.scrollY;
    const speed = options.speed;

    for (const item of elements) {
      // normalized scroll position of element
      const normalizedScroll = windowY - item.offsetTop;

      // calculate an offset so the elemnt is in the center of the screen once half way scrolled
      const offset = ((vpH - item.getBoundingClientRect().top) / 2) * speed;

      let transformOffset = -normalizedScroll * speed - offset;
      transformOffset = clamp(transformOffset, -offset, offset);
      item.style.transform = `translateY(${transformOffset}px)`;
    }

    requestAnimationFrame(onAnimationFrame);
  }

  // kick start raf
  requestAnimationFrame(onAnimationFrame);
};

/**
 * Animates the stroke of a path to fill
 * @param {string} selector - A css selector string
 * @param {Object} [options] - Animation options
 * @param {boolean} [options.once=true] - If animation should play once
 * @param {string} [options.easing="ease"] - CSS animation function
 * @param {number} [options.delay=0] - Delay till animation starts in ms
 * @param {number} [options.duration=1000] - Animation duration in ms
 */
export const svgPathFill = (selector, options) => {
  // test if elements are of valid type path?
  const elements = document.querySelectorAll(selector);

  if (elements.length < 1) return; // check if any element is selected

  // interaction observer options
  const ops = {
    root: null, // viewport
    threshold: 0,
    rootMargin: '0px',
  };

  // add forward/backward option?
  // default settings
  const defaults = {
    once: true,
    easing: 'ease',
    delay: 0,
    duration: 1000,
  };

  // merge defaults with user options
  options = Object.assign(defaults, options);

  const observer = new IntersectionObserver((entries, observer) => {
    entries.forEach((entry) => {
      if (entry.isIntersecting) {
        // element entered view
        const path = entry.target.querySelector(selector);
        const length = path.getTotalLength();
        // Clear any previous transition
        path.style.transition = path.style.WebkitTransition = 'none';
        // Set up the starting positions
        path.style.strokeDasharray = `${length} ${length}`;
        path.style.strokeDashoffset = length;
        // Trigger a layout recalculation so styles are recalculated before animating
        path.getBoundingClientRect();
        // Define transition
        path.style.transition =
          path.style.WebkitTransition = `stroke-dashoffset ${
            options.duration / 1000
          }s ${options.delay / 1000}s ${options.easing}`;
        // Go!
        path.style.strokeDashoffset = '0';

        if (options.once) {
          observer.unobserve(entry.target);
        }
      } else {
        // element out of view
      }
    });
  }, ops);

  // add observer to elements
  for (const el of elements) {
    const parentSvg = el.closest('svg');

    // set initial stroke styles
    const length = el.getTotalLength();
    el.style.strokeDasharray = `${length} ${length}`;
    el.style.strokeDashoffset = length;

    // add interaction observer to parent because elements inside svg don't work interaction observers
    observer.observe(parentSvg);
  }
};

/**
 * Linearly interpolates between two points
 * @param {number} a Start value
 * @param {number} b End value
 * @param {number} t Value used to interpolate between a and b range
 */
export const lerp = (a, b, t) => {
  return a * (1 - t) + b * t;
};

/**
 * Clamp `num` to the range `[min, max]`
 * @param {number} num
 * @param {number} min
 * @param {number} max
 */
export const clamp = (num, min, max) => {
  return num < min ? min : num > max ? max : num;
};

/**
 * Throttle a function with a certain delay between calls
 * @param {Function} callback - Function to be called after delay
 * @param {number} delay - Delay in ms between function calls
 */
export const throttle = (callback, delay) => {
  let previousCall = new Date().getTime();
  return function () {
    let time = new Date().getTime();

    if (time - previousCall >= delay) {
      previousCall = time;
      callback.apply(null, arguments);
    }
  };
};
