export const reframe = (target, cName, resize) => {

    var frames =
        typeof target === 'string' ? document.querySelectorAll(target) : target;
    var c = cName || 'js-reframe';
    if (!('length' in frames)) frames = [frames];
    for (var i = 0; i < frames.length; i += 1) {

        if (!resize) {
            var frame = frames[i];
            var hasClass = frame.className.split(' ').indexOf(c) !== -1;
            if (hasClass || frame.style.width.indexOf('%') > -1) continue;
            var h = frame.getAttribute('height') || frame.offsetHeight;
            var w = frame.getAttribute('width') || frame.offsetWidth;
            var padding = (h / w) * 100;
            var div = document.createElement('div');
            div.className = c;
            var divStyles = div.style;
            divStyles.position = 'relative';
            divStyles.width = '100%';
            divStyles.paddingTop = padding + '%';
            var frameStyle = frame.style;
            frameStyle.position = 'absolute';
            frameStyle.width = '100%';
            frameStyle.height = '100%';
            frameStyle.left = '0';
            frameStyle.top = '0';


            frame.parentNode.insertBefore(div, frame);
            frame.parentNode.removeChild(frame);
            div.appendChild(frame);

            if (innerWidth < 768) {
                if (div.parentNode.classList.contains('home-header-thumb')) {
                    frameStyle.width = 'calc(100vh * (' + w / h + '))';
                } else if (div.parentNode.classList.contains('performance-item-thumb') || div.parentNode.classList.contains('featured-performance-thumb')) {
                    frameStyle.width = 'calc(150vw * (' + w / h + '))';
                } else if (div.parentNode.classList.contains('performance-thumb')) {
                    frameStyle.width = 'calc(155vw * (' + w / h + '))';
                }
            }

        } else {

            let frames = document.querySelectorAll('.home-header-thumb .js-reframe, .performance-item-thumb .js-reframe, .featured-performance-thumb .js-reframe, .performance-thumb .js-reframe');

            frames.forEach(frame => {

                var h = frame.querySelector('iframe').getAttribute('height') ;
                var w = frame.querySelector('iframe').getAttribute('width');

                if (innerWidth < 768) {

                    if (frame.parentNode.classList.contains('home-header-thumb')) {
                        frame.style.width = 'calc(100vh * (' + w / h + '))';
                    } else if (frame.parentNode.classList.contains('performance-item-thumb') || frame.parentNode.classList.contains('featured-performance-thumb')) {
                        frame.style.width = 'calc(150vw * (' + w / h + '))';
                    } else if (frame.parentNode.classList.contains('performance-thumb')) {
                        frame.style.width = 'calc(155vw * (' + w / h + '))';
                    }

                } else {
                    frame.style.width = '100%';
                }
            });


        }


    }
}