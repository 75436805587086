/*********************
 *
 * JAVASCRIPT
 *
 *********************/

/*********************
 * IMPORT STYLES
 *********************/

import '../scss/style.scss';
import '../scss/admin.scss';
import '../scss/login.scss';

/*********************
 * IMPORT LIBRARIES (REMOVE IF UNUSED)
 *********************/

import Swiper from 'swiper';
import {Pagination, Autoplay} from 'swiper/modules';

/*import {
    animateOnScroll,
    advancedSticky,
    parralax,
    svgPathFill
} from './libs/animation';*/

import {localScroll} from './libs/local-scroll';
import {reframe} from './libs/reframe';
import {animateOnScroll} from "./libs/animation";

/*********************
 * GLOBAL VARIABLES
 *********************/

let clampFontSize;
let prevVpWidth;
let vpWidth;
let vpHeight;

const bodyElement = document.querySelector('body');
const header = document.querySelector('.header');

/*********************
 * CLAMP FONT SIZE VARIABLE
 *********************/

function updateClampFontSize() {
    clampFontSize = parseFloat(window.getComputedStyle(document.documentElement).getPropertyValue('font-size')) / 16;
}

updateClampFontSize();

/*********************
 * GET VIEWPORT DIMENSIONS
 *********************/

function updateViewportDimensions() {
    vpWidth = window.innerWidth || document.documentElement.clientWidth;
    vpHeight = window.innerHeight || document.documentElement.clientHeight;
}

updateViewportDimensions();

/*********************
 * RESIZE
 *********************/

window.addEventListener(
    'resize',
    throttle(() => {

        let oldVpWidth = vpWidth;

        updateViewportDimensions();
        updateClampFontSize();
        //adjustMenu();


        if((oldVpWidth < 768 && vpWidth >= 768) || (oldVpWidth >= 768 && vpWidth < 768)) {
            const selectors = [
                "iframe[src*='player.vimeo.com']",
                "iframe[src*='vimeo.com']",
                "iframe[src*='youtube.com']",
                "iframe[src*='youtube-nocookie.com']",
                "iframe[src*='kickstarter.com'][src*='video.html']",
                'object',
                'embed',
            ];
            reframe(selectors.join(','), '', true);
        }

    }, 75)
);

/*********************
 * FIX BODY
 *********************/

function fixBody(bodyClass) {
    let scrollPosition = window.pageYOffset;
    bodyElement.classList.add(bodyClass);
    bodyElement.style.top = '-' + scrollPosition + 'px';
    bodyElement.setAttribute('data-scroll', String(scrollPosition));
}

/*********************
 * UNFIX BODY
 *********************/

function unfixBody(bodyClass) {
    let scrollPosition = bodyElement.getAttribute('data-scroll');
    bodyElement.classList.remove(bodyClass);
    bodyElement.style.removeProperty('top');
    window.scrollTo(0, parseInt(scrollPosition));
}

/*********************
 * ADJUST MENU
 *********************/

function adjustMenu() {
    const nav = document.querySelector('.main-nav');
    const activeSubMenu = nav.querySelector('.sub-menu.active');

    if (vpWidth < 1024 && prevVpWidth >= 1024) {

        unfixBody('menu-active');
        nav.classList.remove('active', 'sub-menu-active');
        if (activeSubMenu) {
            activeSubMenu.classList.remove('active');
        }

    } else if (vpWidth >= 1024 && prevVpWidth < 1024) {

        unfixBody('menu-active');
        nav.classList.remove('active', 'sub-menu-active');
        if (activeSubMenu) {
            activeSubMenu.classList.remove('active');
        }

    }

    prevVpWidth = vpWidth;
}

/*********************
 * MENU TOGGLE
 *********************/

const menuToggle = header.querySelector('.menu-toggle');
if (menuToggle) {
    menuToggle.addEventListener('click', (e) => {
        e.preventDefault();

        const nav = document.querySelector('.main-nav');
        const name = menuToggle.querySelector('.menu-name');

        if (nav.classList.contains('active')) {
            nav.classList.remove('active');
            name.innerHTML = 'Menu';
            unfixBody('menu-active');
        } else {
            nav.classList.add('active');
            if (safe.current_language === 'nl') {
                name.innerHTML = 'Sluit';
            } else if (safe.current_language === 'en') {
                name.innerHTML = 'Close';
            }
            fixBody('menu-active');
        }
    });
}

/*********************
 * SUB MENU
 *********************/

const subMenuParents = header.querySelectorAll('.menu-item-has-children');
if (subMenuParents.length) {

    const nav = header.querySelector('.main-nav');

    subMenuParents.forEach((element) => {

        const link = element.querySelector('a');
        const subMenu = element.querySelector('.sub-menu')

        link.addEventListener('click', (e) => {

            if (vpWidth < 1024 && (element.classList.contains('menu-item-empty-url') || (e.clientX - link.getBoundingClientRect().left) > link.offsetWidth)) {
                e.preventDefault();
                nav.classList.toggle('sub-menu-active');
                subMenu.classList.toggle('active');
            }

        });

    });

}

/*********************
 * PAGE LOAD
 *********************/

document.addEventListener('DOMContentLoaded', (event) => {
    document.querySelector('body').classList.add('js-active');

    const selectors = [
        "iframe[src*='player.vimeo.com']",
        "iframe[src*='vimeo.com']",
        "iframe[src*='youtube.com']",
        "iframe[src*='youtube-nocookie.com']",
        "iframe[src*='kickstarter.com'][src*='video.html']",
        'object',
        'embed',
    ];
    reframe(selectors.join(',', '', false));

    // Object fit polyfill for IE
    if (!Modernizr.objectfit) {
        document.querySelectorAll('.img-container').forEach((el) => {
            const imgUrl = el.querySelector('img').src;
            if (imgUrl) {
                el.style.backgroundImage = 'url(' + imgUrl + ')';
                el.classList.add('compat-object-fit');
            }
        });
    }

    animateOnScroll('.performance-item .performance-title', {type: 'fade-right', duration: 1300, offset: -80});

    /*setTimeout(function () {
        animationText();
    }, 400);*/
});

/*********************
 * LOCAL SCROLL
 *********************/

const links = document.querySelectorAll('a[href*="#"]:not([href="#"])');

links.forEach((element) => {
    const hrefUrl = new URL(element.href);

    element.addEventListener('click', (e) => {

        if (window.location.hostname === hrefUrl.hostname && window.location.pathname === hrefUrl.pathname) {
            e.preventDefault();
            const hashLink = hrefUrl.hash;

            if (hashLink) {
                const linkTarget = document.querySelector(hashLink);

                if (linkTarget) {
                    const menu = document.querySelector('.main-nav');
                    const targetTop = linkTarget.offsetTop;
                    if (menu.classList.contains('active')) {
                        menu.classList.remove('active');
                        unfixBody('menu-active');
                        document.querySelector('.menu-toggle .menu-name').innerHTML = 'Menu';
                    }
                    localScroll(document.documentElement, targetTop, 1000);
                }
            }
        }

    });
});

/*********************
 * LOCAL NAV
 *********************/

const localNav = document.querySelector('.local-nav');
if (localNav) {

    let i = 0;

    document.addEventListener('DOMContentLoaded', (e) => {
        localNavPosition()
    });

    document.addEventListener('scroll', (e) => {
        localNavPosition();
    });

    function localNavPosition() {

        const items = localNav.querySelectorAll('li');
        let scrollTrigger = Math.round(window.scrollY + header.offsetHeight + (clampFontSize * 16) + 1);
        let headingTops = [];

        items.forEach((element) => {

            const link = element.querySelector('a').getAttribute('href');
            const linkName = link.replace('#', '');
            const anchorHeading = document.getElementById(linkName);
            headingTops.push(anchorHeading.offsetTop);

        });

        const firstHeadingTop = headingTops[0];
        const lastHeadingTop = headingTops[headingTops.length - 1];

        headingTops.forEach((headingTop, index) => {

            let nextIndex = index + 1;
            let nextHeadingTop = headingTops[nextIndex];

            if (scrollTrigger >= headingTop && scrollTrigger < nextHeadingTop) {

                items[index].classList.add('active');
                i = 0;

            } else if (scrollTrigger < firstHeadingTop) {

                items[0].classList.add('active');
                i = 0;

            } else if (scrollTrigger >= lastHeadingTop) {

                if (i === 0) {
                    for (let key of headingTops.keys()) {
                        items[key].classList.remove('active');
                    }
                    items[headingTops.length - 1].classList.add('active');
                    i++;
                }

            } else {

                items[index].classList.remove('active');
                i = 0;

            }

        });

    }

}

/*********************
 * ANIMATION TEXT
 *********************/

function animationText() {
    const animationTexts = document.querySelectorAll('.animation-text');
    if (animationTexts.length > 0) {

        let rootMarginOffset = 0;

        const performanceListThumbsContainer = document.querySelector('.performance-list-thumbs');
        if (performanceListThumbsContainer) {
            rootMarginOffset = (vpHeight - performanceListThumbsContainer.offsetHeight) / 2;

            if (rootMarginOffset < 0) {
                rootMarginOffset = 0;
            }
        }

        const animationTextOptions = {
            root: null,
            threshold: 1,
            rootMargin: '-' + rootMarginOffset + 'px 0px -' + rootMarginOffset + 'px 0px'
        }

        const animationTextTriggerObserver = new IntersectionObserver(
            (entries, observer) => {
                entries.forEach((entry) => {
                    if (entry.isIntersecting) {
                        entry.target.classList.add('animated');
                        observer.unobserve(entry.target);
                    }
                });
            },
            animationTextOptions
        );

        animationTexts.forEach((element) => {

            let letters = element.innerHTML.split('');
            element.innerHTML = '';

            letters.forEach((letter) => {

                let span = document.createElement('span');
                span.innerHTML = letter;
                element.appendChild(span);

            });

            let letterSpans = element.querySelectorAll('span');
            let prevTop = 0;
            let i = -1;
            let lines = [];

            letterSpans.forEach((letterSpan) => {

                if (letterSpan.offsetTop !== prevTop) {
                    i++;
                    lines[i] = letterSpan.innerHTML;
                } else {
                    lines[i] += letterSpan.innerHTML;
                }

                prevTop = letterSpan.offsetTop;

            });

            element.innerHTML = '';

            lines.forEach((line) => {

                let span = document.createElement('span');
                span.classList.add('line');
                span.innerHTML = line;
                element.appendChild(span);

            });

            animationTextTriggerObserver.observe(element);

        });

    }
}

/*********************
 * IMAGE SLIDER
 *********************/

const imageSliders = document.querySelectorAll('.image-slider');
if (imageSliders.length > 0) {

    imageSliders.forEach(element => {

        const innerImageSlider = element.querySelector('.inner-image-slider');
        const pagination = element.querySelector('.image-slider-pagination');

        const imageSwiper = new Swiper(innerImageSlider, {
            modules: [Pagination, Autoplay],
            loop: true,
            slidesPerView: 'auto',
            speed: 500,
            pagination: {
                el: pagination,
                clickable: true
            },
            autoplay: {
                delay: 3000,
                disableOnInteraction: false,
                pauseOnMouseEnter: true
            }
        });

    });

}

/*********************
 * VIDEO
 *********************/

const videos = document.querySelectorAll('.video');
if (videos.length > 0) {

    videos.forEach((element) => {

        const placeholder = element.querySelector('.video-placeholder');
        const iframe = element.querySelector('iframe');

        placeholder.addEventListener('click', (e) => {
            e.preventDefault();
            placeholder.classList.add('hidden');
            iframe.src += '&autoplay=1';
        });

    });

}

/*********************
 * PERFORMANCE LIST
 *********************/

const performanceList = document.querySelector('.performance-list');
if (performanceList) {

    const items = performanceList.querySelectorAll('.performance-item');
    const thumbsContainer = performanceList.querySelector('.performance-list-thumbs');
    const thumbs = performanceList.querySelectorAll('.performance-item-thumb');

    document.addEventListener('scroll', (e) => {

        const scrollPosition = window.scrollY - performanceList.offsetTop;
        const firstItemTop = items[0].offsetTop - (vpHeight - ((vpHeight - thumbsContainer.offsetHeight) / 2));
        const lastItemTop = items[items.length - 1].offsetTop - (vpHeight - ((vpHeight - thumbsContainer.offsetHeight) / 2));
        const activeThumb = performanceList.querySelector('.performance-item-thumb.active');

        items.forEach((element, index) => {

            const triggerPoint = element.offsetTop - (vpHeight - ((vpHeight - thumbsContainer.offsetHeight) / 2));
            const nextItem = items[index + 1];
            let nextTriggerPoint = lastItemTop;
            if (nextItem) {
                nextTriggerPoint = nextItem.offsetTop - (vpHeight - ((vpHeight - thumbsContainer.offsetHeight) / 2));
            }

            if (scrollPosition >= triggerPoint && scrollPosition < nextTriggerPoint) {
                if (!thumbs[index].classList.contains('active')) {
                    thumbs[index].classList.add('active');
                }
            } else if (scrollPosition < firstItemTop) {
                if (!thumbs[0].classList.contains('active')) {
                    thumbs[0].classList.add('active');
                }
            } else if (scrollPosition >= lastItemTop) {
                if (!thumbs[items.length - 1].classList.contains('active')) {
                    activeThumb.classList.remove('active');
                    thumbs[items.length - 1].classList.add('active');
                }
            } else {
                if (thumbs[index].classList.contains('active')) {
                    thumbs[index].classList.remove('active');
                }
            }

        });

    });

}

/*********************
 * FOOTNOTES
 *********************/

const footnotes = document.querySelector('.footnotes');
if (footnotes) {

    const marks = footnotes.querySelectorAll('.footnote-mark');
    const textContainer = footnotes.querySelector('.footnotes-text');

    marks.forEach(element => {

        const anchor = element.getAttribute('data-footnote-anchor');
        const text = textContainer.querySelector('.footnote-text[data-footnote-anchor="' + anchor + '"]');

        element.addEventListener('click', (e) => {
            e.preventDefault();

            const activeText = textContainer.querySelector('.footnote-text.active');

            if (vpWidth < 1024) {
                localScroll(document.documentElement, (textContainer.offsetTop - (vpHeight - textContainer.offsetHeight) + (clampFontSize * 20)), 1000);
            }

            if (activeText) {
                activeText.classList.remove('active');
            }

            text.classList.add('active');
        });

        element.addEventListener('mouseenter', (e) => {
            e.preventDefault();
            const activeText = textContainer.querySelector('.footnote-text.active');
            if (activeText) {
                activeText.classList.remove('active');
            }
            text.classList.add('active');
        });

    });

}

/*********************
 * BACKGROUND COLOR CHANGE
 *********************/

document.addEventListener('DOMContentLoaded', (e) => {
    setBackgroundColor();
});

document.addEventListener('scroll', (e) => {
    setBackgroundColor();
});

function setBackgroundColor() {

    const backgroundColorTriggers = document.querySelectorAll('[data-background-color]');
    if (backgroundColorTriggers.length > 0) {

        const scrollPosition = window.scrollY + (innerHeight / 2);

        backgroundColorTriggers.forEach((element) => {

            const top = element.offsetTop;
            const bottom = element.offsetHeight + element.offsetTop;
            const backgroundColor = element.getAttribute('data-background-color');

            if (scrollPosition >= top && scrollPosition < bottom) {
                if (backgroundColor === 'yellow') {
                    bodyElement.style.backgroundColor = '#FFF3C7';
                } else if (backgroundColor === 'purple') {
                    bodyElement.style.backgroundColor = '#E0E3FF';
                } else if (backgroundColor === 'white') {
                    bodyElement.style.backgroundColor = '#FEFDFA';
                }
            }

        });

    }

}

/*const backgroundColorObserverOptions = {
    root: null, // viewport
    threshold: 0,
    rootMargin: '-' + (vpHeight / 2) + 'px 0px -' + (vpHeight / 2) + 'px 0px',
};

const backgroundColorTriggerObserver = new IntersectionObserver(
    (entries, observer) => {
        entries.forEach((entry) => {
            if (entry.isIntersecting) {
                const backgroundColorClass = 'bg-' + entry.target.getAttribute('data-background-color');
                const classExists = Array.from(bodyElement.classList).some(c => c.startsWith('bg-'));
                if (classExists) {
                    bodyElement.classList.remove('bg-yellow', 'bg-purple');
                }
                bodyElement.classList.add(backgroundColorClass);
            }
        });
    },
    backgroundColorObserverOptions
);

const backgroundColorTriggers = document.querySelectorAll('[data-background-color]');
if (backgroundColorTriggers.length > 0) {

    backgroundColorTriggers.forEach(element => {
        backgroundColorTriggerObserver.observe(element);
    });

}*/

/*********************
 * ICON INTERSECTION OBSERVER
 *********************/

const iconObserverOptions = {
    root: null, // viewport
    threshold: 0.5,
    rootMargin: '0px',
};

const iconTriggerObserver = new IntersectionObserver(
    (entries, observer) => {
        entries.forEach((entry) => {
            if (entry.isIntersecting) {
                entry.target.classList.add('animated-in');
                observer.unobserve(entry.target);
            }
        });
    },
    iconObserverOptions
);

const iconTrigger = document.querySelector('.it-icon');
if (iconTrigger) {
    iconTriggerObserver.observe(iconTrigger);
}

/*********************
 * HELPER FUNCTION TO THROTTLE EVENTS
 *********************/

function throttle(callback, delay) {
    let previousCall = new Date().getTime();
    return function () {
        let time = new Date().getTime();

        if (time - previousCall >= delay) {
            previousCall = time;
            callback.apply(null, arguments);
        }
    };
}